import { Badge, Form, Input, Layout, Select } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import FormCard from '../../../../../../components/FormCard/FormCard';
import ActivitySelect from '../../../../../../components/Forms/Company/CompanyActivitySelector/CompanyActivitySelector';
import CategorySelect from '../../../../../../components/Forms/Company/CompanyCategorySelector/CompanyCategorySelector';
import PostalCodeInput from '../../../../../../components/Forms/Company/CompanyPostalCodeInput/CompanyPostalCodeInput';
import RoleWrapper from '../../../../../../components/RoleWrapper/RoleWrapper';
import RequestStatuses from '../../../../../../constants/requestStatuses';
import { UserRoles } from '../../../../../../constants/userRoles';
import nullOrAlt from '../../../../../../helpers/nullOrAlt';
import { asyncActions as asyncCategoriesActions } from '../../../CompaniesCategoriesSliece';
import { actions, asyncActions, selectors } from '../../../CompaniesSlice';
import { TCompanyStatus } from '../../../CompanyType';
import styles from './CompanyInformationTab.module.scss';

const { Content } = Layout;
const { Option } = Select;

type CompanyInformationnTabProps = {
  companyId?: number | null;
  handleCompanyData: (newData: any) => void;
  onSomethingChange: () => void;
};

const initialState = {
  companyName: null as string | null,
  companyDomain: null as string | null,
  nationalId: null as string | null,
  riskGroup: null as string | null,
  address: null as string | null,
  postalCode: null as string | null,
  city: null as string | null,
  country: null as string | null,
  province: null as string | null,
  legalRepresentativeName: null as string | null,
  legalRepresentativeSurname: null as string | null,
  legalRepresentativeTypeOfDoc: null as string | null,
  legalRepresentativeDocNumber: null as string | null,
  category: null as string | null,
  activity: null as string | null,
  email: null as string | null,
  firstName: null as string | null,
  lastName: null as string | null,
  phoneNumber: null as string | null,
  searchMainContact: '',
};

function CompanyInformationTab({
  companyId,
  handleCompanyData,
  onSomethingChange,
}: CompanyInformationnTabProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // selectors
  const currentCompany = useSelector(selectors.currentItem);
  const statuses = useSelector(selectors.statuses);

  // validations
  const companyNameValidation = useSelector(
    selectors.validationError('companyName'),
  );
  const companyLegalNameValidation = useSelector(
    selectors.validationError('companyDomain'),
  );

  // company fields
  const [state, setState] = useState(initialState);
  const [someThingChange, setChangeFlag] = useState(false);

  useEffect(() => {
    dispatch<any>(asyncCategoriesActions.fetchList());
    if (!currentCompany) {
      setState(initialState);
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    dispatch(actions.clearStatuses());
    dispatch(actions.resetValidation());
  }, []);

  const {
    companyName,
    companyDomain,
    nationalId,
    riskGroup,
    address,
    postalCode,
    city,
    country,
    province,
    legalRepresentativeName,
    legalRepresentativeSurname,
    legalRepresentativeTypeOfDoc,
    legalRepresentativeDocNumber,
    category,
    activity,
  } = state;

  useEffect(() => {
    if (currentCompany) {
      handleCompanyData({
        companyName: nullOrAlt(companyName, currentCompany?.companyName || ''),
        companyDomain: nullOrAlt(
          companyDomain,
          currentCompany?.companyInfo.companyDomain || '',
        ),
        nationalId: nullOrAlt(
          nationalId,
          currentCompany?.companyInfo.nationalId || '',
        ),
        riskGroup: nullOrAlt(
          riskGroup,
          currentCompany?.companyInfo.riskGroup || '',
        ),
        address: nullOrAlt(address, currentCompany?.companyInfo.address || ''),
        postalCode: nullOrAlt(
          postalCode,
          currentCompany?.companyInfo.postalCode || '',
        ),
        city: nullOrAlt(city, currentCompany?.companyInfo.city || ''),
        country: nullOrAlt(country, currentCompany?.companyInfo.country || ''),
        province: nullOrAlt(
          province,
          currentCompany?.companyInfo.province || '',
        ),
        category: nullOrAlt(
          category,
          currentCompany?.companyInfo.category || '',
        ),
        activity: nullOrAlt(
          activity,
          currentCompany?.companyInfo.activity || '',
        ),
        legalRepresentativeName: nullOrAlt(
          legalRepresentativeName,
          currentCompany?.companyInfo.legalRepresentativeName || '',
        ),
        legalRepresentativeSurname: nullOrAlt(
          legalRepresentativeSurname,
          currentCompany?.companyInfo.legalRepresentativeSurname || '',
        ),
        legalRepresentativeTypeOfDoc: nullOrAlt(
          legalRepresentativeTypeOfDoc,
          currentCompany?.companyInfo.legalRepresentativeTypeOfDoc || '',
        ),
        legalRepresentativeDocNumber: nullOrAlt(
          legalRepresentativeDocNumber,
          currentCompany?.companyInfo.legalRepresentativeDocNumber || '',
        ),
      });
    }
  }, [state]);

  const statusToBadge = () => {
    switch (currentCompany?.status) {
      case TCompanyStatus.PENDING: {
        return <Badge color="yellow" text={t('generic.pending')} />;
      }
      case TCompanyStatus.ACTIVE: {
        return <Badge color="green" text={t('generic.active')} />;
      }
      default: {
        return <Badge status="default" text={t('generic.inactive')} />;
      }
    }
  };

  const onChange = (field: keyof typeof initialState, value: any) => {
    setState((prevState) => ({ ...prevState, [field]: value }));
    onSomethingChange();
    if (field === 'category') {
      setState((prevState) => ({ ...prevState, activity: '' }));
    }
    !someThingChange && setChangeFlag(true);
  };

  const isCIFValid = () => !nationalId || yup.string().min(1).max(255);

  const serverValidation = () => {
    dispatch(actions.resetValidation());

    const values = Object.entries({
      companyName:
        (companyName || '').trim() !==
        (currentCompany?.companyName || '').trim()
          ? companyName
          : null,
    }).reduce((acc, [key, value]) => {
      if (value) {
        acc[key] = value;
      }
      return acc;
    }, {} as { [key: string]: any });

    if (!Object.keys(values).length) {
      return;
    }

    dispatch<any>(
      asyncActions.validate({
        dataJson: {
          company: {
            ...values,
          },
        },
      }),
    );
  };

  return (
    <>
      <div style={{ width: '80%' }}>
        {!currentCompany && (
          <div className={styles.title}>
            {t('collective.company..form.companyInformation.addLabel')}
          </div>
        )}
        <Layout className={styles.layout}>
          <Content className={styles.content}>
            <FormCard className={styles.card}>
              <>
                <>
                  <div className={styles.title}>
                    {t('collective.company.form.companyInformation.title')}
                  </div>
                  <div className={styles.sectionContainer}>
                    <div className={styles.basicInfo}>
                      <Form className={styles.form} layout="vertical">
                        <div className={styles.basicRowContainer}>
                          <Form.Item
                            label={t(
                              'collective.company.form.companyInformation.companyName',
                            )}
                            className={styles.itemLeftContainer}
                            validateStatus={(() => {
                              if (
                                statuses.validate === RequestStatuses.LOADING
                              ) {
                                return 'validating';
                              }
                              if (companyNameValidation) {
                                return 'error';
                              }
                              return '';
                            })()}
                            help={
                              companyNameValidation
                                ? t(
                                    'collective.company.errors.nameAlreadyExists',
                                  )
                                : null
                            }
                          >
                            <RoleWrapper
                              roles={[UserRoles.ADMIN]}
                              alternative={
                                <div className={styles.companyDetailsValue}>
                                  {currentCompany?.companyName}
                                </div>
                              }
                            >
                              <Input
                                onChange={(e) =>
                                  onChange('companyName', e.target.value)
                                }
                                placeholder={t('generic.example') || ''}
                                value={nullOrAlt(
                                  companyName,
                                  currentCompany?.companyName || '',
                                )}
                                onBlur={serverValidation}
                              />
                            </RoleWrapper>
                          </Form.Item>
                          <Form.Item
                            label={t(
                              'collective.company.form.companyInformation.companyLegalName',
                            )}
                            className={styles.itemRightContainer}
                            validateStatus={(() => {
                              if (
                                statuses.validate === RequestStatuses.LOADING
                              ) {
                                return 'validating';
                              }

                              if (companyLegalNameValidation) {
                                return 'error';
                              }

                              return '';
                            })()}
                            help={
                              companyLegalNameValidation
                                ? t(
                                    'collective.company.errors.legalNameAlreadyExists',
                                  )
                                : null
                            }
                          >
                            <RoleWrapper
                              roles={[UserRoles.ADMIN]}
                              alternative={
                                <div className={styles.companyDetailsValue}>
                                  {currentCompany?.companyInfo.companyDomain}
                                </div>
                              }
                            >
                              <Input
                                onChange={(e) =>
                                  onChange('companyDomain', e.target.value)
                                }
                                placeholder={
                                  t(
                                    'collective.company.form.companyInformation.companyLegalName',
                                  ) || ''
                                }
                                value={nullOrAlt(
                                  companyDomain,
                                  currentCompany?.companyInfo.companyDomain ||
                                    '',
                                )}
                                onBlur={serverValidation}
                              />
                            </RoleWrapper>
                          </Form.Item>
                        </div>
                        <div className={styles.basicRowContainer}>
                          <Form.Item
                            label={t(
                              'collective.company.form.companyInformation.cif',
                            )}
                            className={styles.itemLeftContainer}
                          >
                            <RoleWrapper
                              roles={[UserRoles.ADMIN]}
                              alternative={
                                <div className={styles.companyDetailsValue}>
                                  {currentCompany?.companyInfo.nationalId}
                                </div>
                              }
                            >
                              <Input
                                className={
                                  isCIFValid() ? '' : styles.inputError
                                }
                                min={0}
                                onChange={(e) =>
                                  onChange('nationalId', e.target.value)
                                }
                                placeholder={
                                  t(
                                    'collective.company.form.companyInformation.cif',
                                  ) || ''
                                }
                                value={nullOrAlt(
                                  nationalId,
                                  currentCompany?.companyInfo.nationalId || '',
                                )}
                              />
                            </RoleWrapper>
                          </Form.Item>
                          <CategorySelect
                            category={nullOrAlt(
                              category,
                              currentCompany?.companyInfo.category || '',
                            )}
                            onChange={(val) => {
                              onChange('category', val.trim());
                            }}
                            alternative={currentCompany?.companyInfo.category}
                          />
                        </div>
                        <div className={styles.basicRowContainer}>
                          <ActivitySelect
                            activity={nullOrAlt(
                              activity,
                              currentCompany?.companyInfo.activity || '',
                            )}
                            category={nullOrAlt(
                              category,
                              currentCompany?.companyInfo.category || '',
                            )}
                            onChange={(val) => {
                              onChange('activity', val.trim());
                            }}
                            alternative={currentCompany?.companyInfo.activity}
                          />
                          <Form.Item
                            label={t(
                              'collective.company.form.companyInformation.riskGroup',
                            )}
                            className={styles.itemRightContainer}
                          >
                            <RoleWrapper
                              roles={[UserRoles.ADMIN]}
                              alternative={
                                <div className={styles.companyDetailsValue}>
                                  {currentCompany?.companyInfo.riskGroup}
                                </div>
                              }
                            >
                              <Input
                                onChange={(e) =>
                                  onChange('riskGroup', e.target.value)
                                }
                                placeholder={
                                  t(
                                    'collective.company.form.companyInformation.riskGroup',
                                  ) || ''
                                }
                                value={nullOrAlt(
                                  riskGroup,
                                  currentCompany?.companyInfo.riskGroup || '',
                                )}
                              />
                            </RoleWrapper>
                          </Form.Item>
                        </div>
                        <div
                          style={{ width: '100%' }}
                          className={styles.basicRowContainer}
                        >
                          <div
                            style={{ width: '50.7%' }}
                            className={styles.basicRowContainer}
                          >
                            <Form.Item
                              label={t(
                                'collective.company.form.companyInformation.address',
                              )}
                              className={styles.itemLeftContainer}
                            >
                              <RoleWrapper
                                roles={[UserRoles.ADMIN]}
                                alternative={
                                  <div className={styles.companyDetailsValue}>
                                    {currentCompany?.companyInfo.address}
                                  </div>
                                }
                              >
                                <Input
                                  onChange={(e) =>
                                    onChange('address', e.target.value)
                                  }
                                  placeholder={
                                    t(
                                      'collective.company.form.companyInformation.address',
                                    ) || ''
                                  }
                                  value={nullOrAlt(
                                    address,
                                    currentCompany?.companyInfo.address || '',
                                  )}
                                />
                              </RoleWrapper>
                            </Form.Item>
                          </div>
                          <div
                            style={{ width: '49.3%' }}
                            className={styles.basicRowContainer}
                          >
                            <PostalCodeInput
                              onChange={(val) => {
                                onChange('postalCode', val);
                              }}
                              postalCode={nullOrAlt(
                                postalCode,
                                currentCompany?.companyInfo.postalCode || '',
                              )}
                              alternative={
                                currentCompany?.companyInfo.postalCode
                              }
                            />
                            <Form.Item
                              label={t(
                                'collective.company.form.companyInformation.city',
                              )}
                              className={styles.itemRightContainer}
                            >
                              <RoleWrapper
                                roles={[UserRoles.ADMIN]}
                                alternative={
                                  <div className={styles.companyDetailsValue}>
                                    {currentCompany?.companyInfo.city}
                                  </div>
                                }
                              >
                                <Input
                                  onChange={(e) =>
                                    onChange('city', e.target.value)
                                  }
                                  placeholder={
                                    t(
                                      'collective.company.form.companyInformation.city',
                                    ) || ''
                                  }
                                  value={nullOrAlt(
                                    city,
                                    currentCompany?.companyInfo.city || '',
                                  )}
                                />
                              </RoleWrapper>
                            </Form.Item>
                          </div>
                        </div>
                        <div className={styles.basicRowContainer}>
                          <Form.Item
                            label={t(
                              'collective.company.form.companyInformation.region',
                            )}
                            className={styles.itemLeftContainer}
                          >
                            <RoleWrapper
                              roles={[UserRoles.ADMIN]}
                              alternative={
                                <div className={styles.companyDetailsValue}>
                                  {currentCompany?.companyInfo.province}
                                </div>
                              }
                            >
                              <Input
                                onChange={(e) =>
                                  onChange('province', e.target.value)
                                }
                                placeholder={
                                  t(
                                    'collective.company.form.companyInformation.region',
                                  ) || ''
                                }
                                value={nullOrAlt(
                                  province,
                                  currentCompany?.companyInfo.province || '',
                                )}
                              />
                            </RoleWrapper>
                          </Form.Item>
                          <Form.Item
                            label={t(
                              'collective.company.form.companyInformation.country',
                            )}
                            className={styles.itemRightContainer}
                          >
                            <RoleWrapper
                              roles={[UserRoles.ADMIN]}
                              alternative={
                                <div className={styles.companyDetailsValue}>
                                  {currentCompany?.companyInfo.country}
                                </div>
                              }
                            >
                              <Input
                                onChange={(e) =>
                                  onChange('country', e.target.value)
                                }
                                placeholder={
                                  t(
                                    'collective.company.form.companyInformation.country',
                                  ) || ''
                                }
                                value={nullOrAlt(
                                  country,
                                  currentCompany?.companyInfo.country || '',
                                )}
                              />
                            </RoleWrapper>
                          </Form.Item>
                        </div>
                      </Form>
                    </div>
                  </div>
                </>
                <>
                  <div className={styles.title}>
                    {t('collective.company.form.legalRepresentative.title')}
                  </div>
                  <div className={styles.sectionContainer}>
                    <div className={styles.basicInfo}>
                      <Form className={styles.form} layout="vertical">
                        <div className={styles.basicRowContainer}>
                          <Form.Item
                            label={t(
                              'collective.company.form.legalRepresentative.name',
                            )}
                            className={styles.itemLeftContainer}
                          >
                            <RoleWrapper
                              roles={[UserRoles.ADMIN]}
                              alternative={
                                <div className={styles.companyDetailsValue}>
                                  {
                                    currentCompany?.companyInfo
                                      .legalRepresentativeName
                                  }
                                </div>
                              }
                            >
                              <Input
                                onChange={(e) =>
                                  onChange(
                                    'legalRepresentativeName',
                                    e.target.value,
                                  )
                                }
                                placeholder={
                                  t(
                                    'collective.company.form.legalRepresentative.name',
                                  ) || ''
                                }
                                value={nullOrAlt(
                                  legalRepresentativeName,
                                  currentCompany?.companyInfo
                                    .legalRepresentativeName || '',
                                )}
                              />
                            </RoleWrapper>
                          </Form.Item>
                          <Form.Item
                            label={t(
                              'collective.company.form.legalRepresentative.surname',
                            )}
                            className={styles.itemRightContainer}
                          >
                            <RoleWrapper
                              roles={[UserRoles.ADMIN]}
                              alternative={
                                <div className={styles.companyDetailsValue}>
                                  {
                                    currentCompany?.companyInfo
                                      .legalRepresentativeSurname
                                  }
                                </div>
                              }
                            >
                              <Input
                                onChange={(e) =>
                                  onChange(
                                    'legalRepresentativeSurname',
                                    e.target.value,
                                  )
                                }
                                placeholder={
                                  t(
                                    'collective.company.form.legalRepresentative.surname',
                                  ) || ''
                                }
                                value={nullOrAlt(
                                  legalRepresentativeSurname,
                                  currentCompany?.companyInfo
                                    .legalRepresentativeSurname || '',
                                )}
                              />
                            </RoleWrapper>
                          </Form.Item>
                        </div>
                        <div
                          style={{ width: '50.7%' }}
                          className={styles.basicRowContainer}
                        >
                          <RoleWrapper roles={[UserRoles.ADMIN]}>
                            <Form.Item
                              label={t(
                                'collective.company.form.legalRepresentative.documentType',
                              )}
                              className={styles.itemLeftContainer}
                            >
                              <Select
                                onChange={(e: string) =>
                                  onChange('legalRepresentativeTypeOfDoc', e)
                                }
                                placeholder={t('generic.pleaseSelect')}
                                value={nullOrAlt(
                                  legalRepresentativeTypeOfDoc,
                                  currentCompany?.companyInfo
                                    .legalRepresentativeTypeOfDoc || '',
                                )}
                              >
                                <Option value="NIE">NIE</Option>
                                <Option value="NIF">NIF</Option>
                              </Select>
                            </Form.Item>
                          </RoleWrapper>
                          <Form.Item
                            label={t(
                              'collective.company.form.legalRepresentative.document',
                            )}
                            className={styles.itemLeftContainer}
                          >
                            <RoleWrapper
                              roles={[UserRoles.ADMIN]}
                              alternative={
                                <div className={styles.companyDetailsValue}>
                                  {
                                    currentCompany?.companyInfo
                                      .legalRepresentativeTypeOfDoc
                                  }
                                  :
                                  {
                                    currentCompany?.companyInfo
                                      .legalRepresentativeDocNumber
                                  }
                                </div>
                              }
                            >
                              <Input
                                onChange={(e) =>
                                  onChange(
                                    'legalRepresentativeDocNumber',
                                    e.target.value,
                                  )
                                }
                                placeholder={t('generic.example') || ''}
                                value={nullOrAlt(
                                  legalRepresentativeDocNumber,
                                  currentCompany?.companyInfo
                                    .legalRepresentativeDocNumber || '',
                                )}
                              />
                            </RoleWrapper>
                          </Form.Item>
                        </div>
                      </Form>
                    </div>
                  </div>
                </>
              </>
            </FormCard>
          </Content>
        </Layout>
      </div>
      <div style={{ width: '20%' }} className={styles.companyOverViewContainer}>
        <span className={styles.overviewTitle}>{t('generic.overview')}</span>
        <div className={styles.overviewInvitationCodeContainer}>
          <div className={styles.overviewOptionTitle}>
            <span>
              {t('collective.company..form.companyInformation.companyCode')}
            </span>
          </div>
          <div className={styles.overviewInvitationCode}>
            <span>{currentCompany?.companyCode}</span>
          </div>
        </div>
        <RoleWrapper roles={[UserRoles.ADMIN]}>
          <div className={styles.overviewOptionContainer}>
            <div className={styles.overviewOptionTitle}>
              <span>
                {t('collective.company..form.companyInformation.currentStatus')}
              </span>
            </div>
            <span>{statusToBadge()}</span>
          </div>
        </RoleWrapper>
        <div className={styles.overviewOptionContainer}>
          <div className={styles.overviewOptionTitle}>
            <span>
              {t('collective.company..form.companyInformation.companyId')}
            </span>
          </div>
          <span>{currentCompany?.id}</span>
        </div>
        <RoleWrapper roles={[UserRoles.ADMIN]}>
          <>
            <div className={styles.overviewOptionContainer}>
              <div className={styles.overviewOptionTitle}>
                <span>
                  {t('collective.company..form.companyInformation.created')}
                </span>
              </div>
              <span>
                {dayjs(currentCompany?.createdAt).format('DD/MM/YYYY HH:mm')}
              </span>
            </div>
            <div className={styles.overviewOptionContainer}>
              <div className={styles.overviewOptionTitle}>
                <span>
                  {t('collective.company..form.companyInformation.lastUpdate')}
                </span>
              </div>
              <span>
                {dayjs(currentCompany?.updatedAt).format('DD/MM/YYYY HH:mm')}
              </span>
            </div>
          </>
        </RoleWrapper>
      </div>
    </>
  );
}

CompanyInformationTab.defaultProps = {
  companyId: null,
};

export default CompanyInformationTab;
